<template>
  <div ref="contentRef">
    <!-- <van-loading v-if="!visible" class="flex-center h-[500px]" size="20"></van-loading>
    <template v-else> -->
    <slot :visible="visible"></slot>
    <!-- </template> -->
  </div>
</template>

<script setup>
import {useIntersectionObserver} from '@vueuse/core';

const contentRef = ref(null)
const visible = ref(false)

const emit = defineEmits(['loaded'])
const {stop} = useIntersectionObserver(
    contentRef,
    ([{isIntersecting}]) => {
      if (isIntersecting && !visible.value) {
        visible.value = true
        emit('loaded')
        stop()
      }
    },
    {
      threshold: 0,
    }
)

onUnmounted(() => {
  stop()
})

</script>